<template>
  <v-tab-item>
    <v-card flat class="sixth rounded-0 py-lg-8 pt-4 my-auto">
      <v-card-text>
        <v-row class="d-flex align-center sm-mx-auto ml-auto">
          <v-col cols="12" sm="9" md="7" lg="5" xl="4" class="mx-auto">
            <v-card class="px-lg-16 px-4 py-4 divCard primary--text" outlined>
              <v-text-field
                background-color="secondary"
                v-model="loyaltyData.loyaltyPoint"
                :rules="[validRule.numberWithDecimal]"
                label="Loyalty points"
                @change="updateInStore()"
              ></v-text-field>
              <div class="d-flex">
                <v-text-field
                  background-color="secondary"
                  v-model="panFromStore"
                  label="Hash pan number(credit card)"
                  readonly
                >
                  <template v-slot:append>
                   <CopyValue :valueToCopy="panFromStore"/>
                    <UpdateHashPan class="my-auto ml-auto" />
                  </template>
                </v-text-field>
              </div>
              <v-text-field
                background-color="secondary"
                v-model="loyaltyData.cardNumber"
                :rules="[validRule.numberOnly]"
                label="Loyalty card number"
                @change="updateInStore()"
              ></v-text-field>
              <v-text-field
                background-color="secondary"
                v-model="loyaltyData.dateCreation"
                label="Date creation"
                @change="updateInStore()"
                disabled
              ></v-text-field>
              <v-text-field
                background-color="secondary"
                v-model="loyaltyData.phoneNumber"
                :rules="[validRule.numberOnly]"
                label="Phone number"
                @change="updateInStore()"
              ></v-text-field>
              <v-text-field
                background-color="secondary"
                v-model="loyaltyData.email"
                label="E-mail"
                @change="updateInStore()"
              ></v-text-field>
              <v-text-field
                background-color="secondary"
                v-model="loyaltyData.id"
                label="Id"
                disabled
              ></v-text-field>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-tab-item>
</template>

<script>
import UpdateHashPan from "@/components/UpdateHashPan";
import { REGEX, checkRegex } from "@/assets/js/utils";
import CopyValue from '@/components/Button/CopyValue';

export default {
  name: "Loyalty",
  components: { UpdateHashPan,CopyValue },
  data() {
    return {
      loyaltyData: {
        phoneNumber: "0",
        panNumber: "",
        email: "",
        dateCreation: "",
        cardNumber: "",
        id: "",
        loyaltyPoint: "",
      },
      locked: true,
    };
  },
  methods: {
    updateInStore() {
      if (
        checkRegex(REGEX.numberWithDecimal, this.loyaltyData.loyaltyPoint) &&
        checkRegex(
          REGEX.numberOnly,
          this.loyaltyData.phoneNumber,
          this.loyaltyData.cardNumber
        )
      ) {
        this.loyaltyData.panNumber = this.$store.state.loyaltyData.panNumber;
        this.$store.commit("setLoyaltyData", this.loyaltyData);
      }
    },
    fillUp() {
      this.loyaltyData = this.$store.state.loyaltyData;
    },
  },
  computed: {
    panFromStore: {
      get() {
        return this.$store.state.loyaltyData.panNumber;
      },
      set() {},
    },
    validRule() {
      return this.$store.state.validateRule;
    },
  },
};
</script>

<style scoped>
.divCard {
  border-top: 5px solid #343430;
  border-bottom: 5px solid #343430;
}
.v-input--is-disabled:not(.v-input--is-readonly) .v-icon.v-icon--disabled {
  pointer-events: auto;
}
</style>
