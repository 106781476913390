<template>
  <div
    class=" divGlobale  sixth  d-flex flex-row-reverse justify-space-around "
  >
    <div class="d-flex flex-column divB  px-md-0 sixth ">
      <div class="my-auto mx-0 secondary px-3">
        <h1
          class="primary--text mx-4 font-weight py-5 text-md-h3 text-xs-h2 mx-auto text-center"
        >
          {{ customer.firstName }} {{ customer.lastName }}
        </h1>
        <div class="d-flex flex-column py-md-5 mx-4 text-center ">
          <p>id : {{ customer.id }}</p>
          <p class="d-flex justify-center flex-column flex-md-row mx-auto">
            complexId : {{ customer.complexId }}
            <CopyValue :valueToCopy="customer.complexId" />
          </p>

          <p class="my-auto py-2 mx-4">
            Navigate between tabs to update customer profile in our web-service.
          </p>
        </div>
      </div>
      <div class="divGlobale secondary">
      <v-container
        class="mx-auto px-3 divB d-flex flex-column secondary justify-center"
      >
        <v-row class="d-flex flex-column justify-center mx-md-auto">
          <v-col cols="12" sm="12" class="py-0">
            <v-card class="rounded-0 sixth" elevation="0">
              <v-tabs
                v-model="tab"
                class="rounded-0"
                icons-and-text
                show-arrows
                centered
              >
                <v-tabs-slider></v-tabs-slider>

                <v-tab class="info--text mx-5" @click="showLeavingBox = false">
                  <p class="text-caption text-sm-subtitle-1 font-weight-medium">
                    Basics infos
                  </p>
                  <v-icon color="primary">mdi-account-box</v-icon>
                </v-tab>

                <v-tab class="info--text mx-5" @click="showLeavingBox = false">
                  <p class="text-caption text-sm-subtitle-1 font-weight-medium">
                    Addresses
                  </p>
                  <v-icon color="primary">mdi-home</v-icon>
                </v-tab>
                <v-tab class="info--text mx-5" @click="showLeavingBox = false">
                  <p class="text-caption text-sm-subtitle-1 font-weight-medium">
                    Security
                  </p>
                  <v-icon color="primary">mdi-lock</v-icon>
                </v-tab>
                <v-tab
                  class="info--text mx-5"
                  @click="
                    !fillLoyaltybol
                      ? (fillLoyalty(), (fillLoyaltybol = true))
                      : '',
                      (modificationsButton = true),
                      (showLeavingBox = false)
                  "
                >
                  <p class="text-caption text-sm-subtitle-1 font-weight-medium">
                    Loyalty
                  </p>
                  <v-icon color="primary">mdi-heart</v-icon>
                </v-tab>
                <v-tab class="info--text mx-5" @click="showLeavingBox = false">
                  <p class="text-caption text-sm-subtitle-1 font-weight-medium">
                    Baskets ({{ basketProducts.length }})
                  </p>
                  <v-icon color="primary">mdi-basket</v-icon>
                </v-tab>

                <div class="mx-auto my-auto d-none d-md-flex justify-center">
                  <v-btn
                    class="primary mx-auto py-6"
                    elevation="0"
                    text
                    :loading="loadingBtn"
                    @click="updateController()"
                  >
                    <p class="my-auto">Save</p>
                    <v-icon class="my-auto ml-2"> mdi-content-save </v-icon>
                  </v-btn>
                </div>
              </v-tabs>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      </div>
      <v-container class="px-0 divB sixth pb-0 mx-auto">
        <v-row class="d-flex flex-column secondary justify-center px-0 sixth mx-auto">
          <v-col>
            <v-card elevation="0" class="rounded-0">
              <v-tabs-items v-model="tab" class="sixth divGlobale">
                <div
                  class="text-center d-flex py-16 justify-center divGlobale align-middle sixth"
                  v-if="loading"
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
                  <v-img src="../assets/kissclipart-icon-clipart-computer-icons-swipe-swap-android-417560e36d201de6(1).png"
                  width="70px"
                  class="mx-auto d-flex d-sm-none handSlide"
                  v-if="handShow"
                  ></v-img>
                <BasicInfos v-show="!loading" class="sixth" />
                <Addresses />
                <v-tab-item>
                  <v-card flat class="sixth rounded-0 py-lg-8 pt-4 my-auto">
                    <v-card-text>
                      <v-row class="d-flex align-center RowText mx-auto">
                        <v-col
                          cols="12"
                          sm="9"
                          md="7"
                          lg="5"
                          xl="4"
                          class="mx-auto"
                        >
                          <v-card
                            class="px-lg-16 px-4 py-4 divCard primary--text"
                            outlined
                          >
                            <v-text-field
                              background-color="secondary"
                              v-model="password"
                              label="New password"
                              :rules="[
                                validRule.password(password, password_b),
                              ]"
                              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show1 ? 'text' : 'password'"
                              @click:append="show1 = !show1"
                            ></v-text-field>
                            <v-text-field
                              background-color="secondary"
                              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show2 ? 'text' : 'password'"
                              @click:append="show2 = !show2"
                              v-model="password_b"
                              label="Confirm password"
                              :rules="[
                                validRule.password(password, password_b),
                              ]"
                            ></v-text-field>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <Loyalty ref="loyaltyRef" />
                <Baskets />
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-btn
      class="primary d-flex d-md-none btnfloat"
      elevation="0"
      fab
      absolute
      :loading="loadingBtn"
      fixed
      top
      right
      @click="updateController()"
    >
      <v-icon class="my-auto mx-auto"> mdi-content-save </v-icon>
    </v-btn>
    <DialogBox
      :dialog="showLeavingBox"
      @leave="(leaveAnyway = true), $router.push(to)"
    />
  </div>
</template>

<script>
import store from "@/store/index.js";
import {
  fillLoyalty,
  fillBasket,
  updateCustomer,
  updatePassword,
  updateLoyalty,
  getInfos,
} from "@/assets/axios/CustomerProfile";

import BasicInfos from "@/components/Tabs/BasicInfos";
import Addresses from "@/components/Tabs/Addresses";
import DialogBox from "@/components/dialogBox";
import Loyalty from "@/components/Tabs/Loyalty.vue";
import Baskets from "@/components/Tabs/Baskets.vue";
import CopyValue from "@/components/Button/CopyValue";
export default {
  components: {
    BasicInfos,
    DialogBox,
    Addresses,
    Loyalty,
    Baskets,
    CopyValue,
  },
  data() {
    return {
      to: "",
      fab: false,
      loading: true,
      tab: null,
      error: false,
      updated: false,
      show1: false,
      show2: false,
      dataChanged: false,
      showLeavingBox: false,
      leaveAnyway: false,
      password: "password",
      password_b: "password",
      fillLoyaltybol: false,
      loadingBtn: false,
    };
  },
  methods: {
    fillLoyalty,
    fillBasket,
    updateCustomer,
    updatePassword,
    updateLoyalty,
    getInfos,
    updateController() {
      this.loadingBtn = true;
      if (
        this.validRule.password(this.password, this.password_b) === true &&
        this.password !== "password"
      ) {
        this.updatePassword();
      }
      if (
        JSON.stringify(this.$store.state.loyaltyData) !==
        JSON.stringify(this.$store.state.loyaltyDataCopy)
      ) {
        this.updateLoyalty();
      }
      if (
        JSON.stringify(this.$store.state.customer) !==
          JSON.stringify(this.$store.state.customerCopy) ||
        JSON.stringify(this.$store.state.customer.addresses) !==
          JSON.stringify(this.$store.state.addressesCopy)
      ) {
        this.updateCustomer();
        this.updateLocalStorage();
        this.setDataChanged(false);
      }
      setTimeout(() => {
        this.loadingBtn = false;
      }, 600);
    },
    updateLocalStorage() {
      if (localStorage.allCustomers !== null) {
        let arr = JSON.parse(localStorage.allCustomers);
        for (let i = 0; i < arr.length; i++) {
          arr[i].complexId;
          if (arr[i].complexId === this.$route.params.complexId) {
            arr[i] = this.customer;
            localStorage.allCustomers = JSON.stringify(arr);
            store.commit("setAllCustomers", arr);
          }
        }
      }
      if (localStorage.allCustomersWithAnonym !== null) {
        let arr = JSON.parse(localStorage.allCustomersWithAnonym);
        for (let i = 0; i < arr.length; i++) {
          arr[i].complexId;
          if (arr[i].complexId === this.$route.params.complexId) {
            arr[i] = this.customer;
            localStorage.allCustomersWithAnonym = JSON.stringify(arr);
          }
        }
      }
    },
    setDataChanged(bol) {
      this.dataChanged = bol;
    },
    checkIfDataHasBeenChanged() {
      if (
        JSON.stringify(this.$store.state.customerCopy) !==
          JSON.stringify(this.$store.state.customer) ||
        this.password !== "password" ||
        this.password_b !== "password" ||
        JSON.stringify(this.$store.state.loyaltyDataCopy) !==
          JSON.stringify(this.$store.state.loyaltyData)
      ) {
        this.setDataChanged(true);
      } else {
        this.setDataChanged(false);
      }
    },
    leaving(next) {
      this.showLeavingBox = false;
      this.checkIfDataHasBeenChanged();
      if (this.leaveAnyway === true) {
        next();
      } else if (this.dataChanged === false) {
        next();
      } else {
        this.showLeavingBox = true;
      }
    },
    fillLoyaltyComponent() {
      this.$refs.loyaltyRef.fillUp();
    },
  },
  mounted() {
    this.getInfos(this.$route.params.complexId);
    this.fillBasket();
    setTimeout(() => {
      this.loading = false;
    }, 600);
    store.commit("updateCustomerComplexId", this.$route.params.complexId);
  },
  computed: {
    handShow(){
      return this.$store.state.handShow;
    },
    size() {
      const size = { xs: "x-small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    basketProducts: {
      get() {
        return store.state.basketProducts;
      },
      set() {},
    },
    customer() {
      return store.state.customer;
    },
    validRule() {
      return this.$store.state.validateRule;
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("sethandShow",false);
    this.to = to;
    this.leaving(next);
  },
};
</script>
<style scoped>
.divGlobale {
  width: 100%;
}
.divB {
  width: 100%;
}
p {
  vertical-align: middle;
}
.divCard {
  border-top: 5px solid #343430;
  border-bottom: 5px solid #343430;
}
.btnfloat {
  margin-top: 100px;
}
@keyframes slide {
  from{
    left: 90%;
    opacity: 0;
  }
  50%{
    opacity: 0.6;
  }
  to{
    left:0px;
 opacity: 0;
 display: none;
  }
}

.handSlide{
  animation-name:slide;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-delay: 1.5;
  animation-direction: normal;
  position: fixed;
  bottom: 100px;

  z-index: 3;
  opacity: 0;
}
</style>
