<template>
  <v-tab-item>
    <v-card flat class="sixth rounded-0 py-lg-8 pt-4 my-auto">
      <v-card-text>
        <v-row class="d-flex align-center sm-mx-auto ml-auto">
          <v-col cols="12" sm="9" md="7" lg="5" xl="4" class="mx-auto">
            <v-card class="px-4 px-lg-16 py-4 divCard primary--text" outlined>
              <v-text-field
                background-color="secondary"
                v-model="customer.firstName"
                label="First name"
                :rules="[validRule.letterOnly]"
                @change="updateInStore()"
              ></v-text-field>
              <v-text-field
                background-color="secondary"
                v-model="customer.lastName"
                :rules="[validRule.letterOnly]"
                label="Last name"
                @change="updateInStore()"
              ></v-text-field>
              <v-text-field
                background-color="secondary"
                v-model="customer.email"
                label="E-mail"
                @change="updateInStore()"
              ></v-text-field>
              <v-text-field
                background-color="secondary"
                v-model="customer.phoneNumber"
                :rules="[validRule.numberOnly]"
                label="Phone number"
                hint="Only number are granted"
                @change="updateInStore()"
              ></v-text-field>
              <v-text-field
                background-color="secondary"
                v-model="customer.smartShelvesId"
                label="Smart shelves id"
                @change="updateInStore()"
              ></v-text-field>
              <v-text-field
                background-color="secondary"
                v-model="customer.faceSignature"
                label="Face signature"
                @change="updateInStore()"
              ></v-text-field>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-tab-item>
</template>

<script>
import { REGEX, checkRegex } from "@/assets/js/utils";
export default {
  name: "BasicInfos",
  data() {
    return {
      customer: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        smartShelvesId: "",
        faceSignature: "",
      },
    };
  },
  methods: {
    updateInStore() {
      if (
        checkRegex(
          REGEX.letterOnly,
          this.customer.firstName,
          this.customer.lastName
        ) &&
        checkRegex(REGEX.numberOnly, this.customer.phoneNumber)
      ) {
        this.$store.commit("updateCustomer", this.customer);
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.customer = this.$store.state.customer;
    }, 500);
  },
  computed: {
    validRule() {
      return this.$store.state.validateRule;
    },
  },
};
</script>
<style scoped>
.divCard {
  border-top: 5px solid #343430;
  border-bottom: 5px solid #343430;
}
</style>
