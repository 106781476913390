<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="380">
      <v-card>
        <v-card-title class="headline">
          <p class="text-no-wrap">Are you sure you want to leave?</p>
        </v-card-title>
        <v-card-text
          >Some data has been changed but not sent. If you leave now changes
          will be lost.</v-card-text
        >
        <v-card-actions>          
          <v-btn color="info" text @click="dialog = false">
            Stay
          </v-btn><v-spacer></v-spacer>
          <v-btn
            class="primary"
            text
            @click="(dialog = false), leave()"
          >
            Leave Anyway
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "DialogBox",
  props: {
    dialog: { type: Boolean, default: false },
  },
  methods: {
    leave() {
      this.$emit("leave");
    },
  },
};
</script>
