<template>
  <div>
    <v-tooltip bottom v-model="show">
      <template v-slot:activator="{}">
        <v-icon
          class="primary--text"
          slot="append-outer"
          :disabled="false"
          @click="(copyToClipboard(valueToCopy), showMessage())"
        >
          mdi-content-copy
        </v-icon>
      </template>
      <span>Data copied to clipboard</span>
    </v-tooltip>
  </div>
</template>
<script>
import { copyToClipboard } from "@/assets/js/Methods";

export default {
  name: "CopyValue",
  data() {
    return {
      show: false,
    };
  },
  props: {
    valueToCopy: { type: String },
  },
  methods: {
    copyToClipboard,
    showMessage() {
      this.show = true
        setTimeout(() => {
          this.show = false;
        }, 2000);
    },
  },
};
</script>
