import axios from "axios";
import {cleanObject} from '@/assets/js/Methods';
export function fillLoyalty() {
  axios({
    url: this.$store.state.url + "/customer/get/loyalty/infos",
    params: {
      jsonInfos: JSON.stringify({
        complexId: this.$route.params.complexId,
      }),
    },
    method: "GET",
  })
    .then((response) => {
      console.log(response.data);
      this.$store.commit("setLoyaltyData", response.data);
      this.$store.commit("setLoyaltyDataCopy", {...response.data});
      this.fillLoyaltyComponent();
    })
    .catch((error) => {
      console.log(error);
      this.$store.dispatch('snackbar/show', {
        message: error, type: "warning", timeout: 5
      });
    });
}
export function updateLoyalty() {
  let dataToSend = cleanObject({
    complexId: this.$store.state.customer.complexId,
    nbPoints: this.$store.state.loyaltyData.loyaltyPoint,
    loyaltyCard:this.$store.state.loyaltyData.cardNumber,
    phone:this.$store.state.loyaltyData.phoneNumber,
    email:this.$store.state.loyaltyData.email,
    hashPan:this.$store.state.loyaltyData.panNumber  
  });
  console.log({...dataToSend})
  axios({
    url: this.$store.state.url + "/customer/update/loyalty/informations",
    params: {
      jsonInfos: JSON.stringify({...dataToSend}),
    },
    method: "POST",
  })
    .then(() => {
      this.$store.commit("setLoyaltyDataCopy",{...this.$store.state.loyaltyData});
        this.$store.dispatch('snackbar/show', {
          message: "Customer updated with success", type: "success", timeout: 5
        });
    })
    .catch((error) => {
      console.log(error);
      this.$store.dispatch('snackbar/show', {
        message: error, type: "warning", timeout: 5
      });
    });
}
export function fillBasket() {
  axios({
    url: this.$store.state.url + "/basket/get/customer/all/complexid",
    params: {
      complexId: this.$route.params.complexId,
    },
    method: "GET",
  })
    .then((response) => {
        this.$store.commit("setBasket", response.data);
    })
    .catch((error) => {
      console.log(error);
      this.$store.dispatch('snackbar/show', {
        message: error, type: "warning", timeout: 5
      });
    });
}
export function updateCustomer() {
  let dataToSend = {
    complexId: this.$store.state.customer.complexId,
    email: this.$store.state.customer.email,
    firstName: this.$store.state.customer.firstName,
    lastName: this.$store.state.customer.lastName,
    phoneNumber: this.$store.state.customer.phoneNumber,
    smartShelvesId: this.$store.state.customer.smartShelvesId,
    faceSignature: this.$store.state.customer.faceSignature
  };
  let dataToSendForAdresses = {
    addressId: this.$store.state.customer.addresses[0].id,
    address: this.$store.state.customer.addresses[0].address,
    city: this.$store.state.customer.addresses[0].city,
    cp: this.$store.state.customer.addresses[0].cp,
    country: this.$store.state.customer.addresses[0].country,
    addressName: this.$store.state.customer.addresses[0].name,
    defaultAddress: this.$store.state.customer.addresses[0].defaultAddress
  };
  axios({
    url: this.$store.state.url + "/customer/update/customer/admin",
    params: {
      jsonCustomer: JSON.stringify({
       ...dataToSend
      }),
      jsonAddress: JSON.stringify({
        ...dataToSendForAdresses
      })
    },
    method: "POST",
  })
    .then(() => {
      this.$store.commit("updateCustomerCopy",{...this.$store.state.customer});
      this.$store.dispatch('snackbar/show', {
        message: "Customer updated with success", type: "success", timeout: 5
      });
    })
    .catch((error) => {
      console.log(error);
      this.$store.dispatch('snackbar/show', {
        message: error, type: "warning", timeout: 5
      });
    });
}
export function updatePassword() {
  axios({
    url: this.$store.state.url + "/customer/reset/password",
    params: {
      email: this.$store.state.customer.email,
      newPassword: this.password,
    },
    method: "POST",
  })
    .then(() => {
      this.$store.dispatch('snackbar/show', {
        message: "Customer updated with success", type: "success", timeout: 5
      });
    })
    .catch((error) => {
      console.log(error);
      this.$store.dispatch('snackbar/show', {
        message: error, type: "warning", timeout: 5
      });
    });
}
export function getInfos(complexId) {
  axios({
    url: this.$store.state.url + "/customer/get/customer/complexid",
    params: {
      complexId: complexId,
    },
    method: 'GET'
  })
    .then(response => {
      this.$store.commit('updateCustomer', response.data);
      this.$store.commit("updateCustomerCopy",{...response.data});
      this.$store.commit('updateCustomerAddressCopy',JSON.parse(JSON.stringify(response.data.addresses)));
      return response.data
    })
    .catch(error => {
      console.log(error)
    })
}