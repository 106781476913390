<template>
  <v-tab-item>
    <v-card flat class="sixth rounded-0 py-lg-8 pt-4 my-auto">
      <v-card-text>
        <v-form>
        <v-row class=" sm-mx-auto ml-auto">
          <v-col cols="12" sm="9" md="7" lg="5" xl="4" class="mx-auto">
            <v-card class="px-4 px-lg-16 py-4 divCard primary--text" outlined>
              <v-text-field
                background-color="secondary"
                v-model="addresses[0].address"
                :rules="[validRule.letterAndNumber]"
                label="Address"
                @change="updateInStore()"
              ></v-text-field>
              <v-text-field
                background-color="secondary"
                v-model="addresses[0].cp"
                label="Post code"
                :rules="[validRule.numberOnly]"
                @change="updateInStore()"
              ></v-text-field>
              <v-text-field
                background-color="secondary"
                v-model="addresses[0].city"
                :rules="[validRule.letterOnly]"
                label="City"
                @change="updateInStore()"
              ></v-text-field>
              <v-text-field
                background-color="secondary"
                v-model="addresses[0].country"
                :rules="[validRule.letterOnly]"
                label="Country"
                @change="updateInStore()"
              ></v-text-field>
              <v-text-field
                background-color="secondary"
                v-model="addresses[0].defaultAddress"
                :rules="[validRule.letterAndNumber]"
                label="Default address"
                @change="updateInStore()"
              ></v-text-field>
              <v-text-field
                background-color="secondary"
                v-model="addresses[0].name"
                :rules="[validRule.letterAndNumber]"
                label="Address name"
                @change="updateInStore()"
              ></v-text-field>
              <v-text-field
                background-color="secondary"
                v-model="addresses[0].id"
                label="Address id"
                disabled
              ></v-text-field>
            </v-card>
          </v-col>
        </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-tab-item>
</template>


<script>
import { REGEX, checkRegex } from "@/assets/js/utils";
export default {
  name: "Addresses",
  data() {
    return {
      addresses: [
        {
          address: "",
          city: "",
          country: "",
          cp: "",
          defaultAddress: "",
          id: "",
          name: "",
        },
      ],
    };
  },
  methods: {
    updateInStore() {
      if (
        checkRegex(
          REGEX.letterOnly,
          this.addresses[0].city,
          this.addresses[0].country,
          this.addresses[0].name
        ) &&
        checkRegex(REGEX.numberOnly, this.addresses[0].cp) &&
        checkRegex(REGEX.letterAndNumber, this.addresses[0].address)
      ) {
        this.$store.commit("updateCustomerAddress", [...this.addresses]);
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.addresses = this.$store.state.customer.addresses;
    }, 600);
  },
  computed:{
    validRule(){return this.$store.state.validateRule}
  }
};
</script>
<style scoped>
.divCard {
  border-top: 5px solid #343430;
  border-bottom: 5px solid #343430;
}
</style>