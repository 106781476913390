<template>
  <v-row class="d-flex justify-end mr-1">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-icon class="primary--text" v-bind="attrs" v-on="on">
          mdi-pencil</v-icon
        >
      </template>
      <v-card>
        <v-card-title>
          <span class="headline primary--text">Edit Hash Pan</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="First six number*"
                  hint="Fill only the first six number of the credit card"
                  persistent-hint
                  maxlength="6"
                  :rule="validateRule"
                  :pattern="number"
                  required
                  v-model="firstSix"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="xxxxxx" disabled></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Last four number*"
                  hint="Fill only the last four number of the credit card"
                  persistent-hint
                  maxlength="4"
                  :pattern="number"
                  required
                  v-model="lastFour"
                  ref="nextInput"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="(dialog = false), (firstSix = ''), (lastFour = '')"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="primary secondary--text" text @click="updateHashpan()">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { REGEX } from "@/assets/js/utils";
let md5 = require("md5");

export default {
  name: "UpdateHashPan",
  data() {
    return {
      dialog: false,
      firstSix: "",
      lastFour: "",
      number: REGEX.numberOnly,
      validateRule: [
        (v) => !!v || "This field is required",
        (v) => v.length > 5,
      ],
    };
  },
  methods: {
    updateHashpan() {
      if (
        this.firstSix.length === 6 &&
        this.lastFour.length === 4 &&
        REGEX.numberOnly.test(this.lastFour) &&
        REGEX.numberOnly.test(this.firstSix)
      ) {
        this.$store.commit(
          "setLoyaltyDataPanNumber",
          md5(this.firstSix + "000000" + this.lastFour)
        );
        this.dialog = false;
      } else {
        this.$store.dispatch("snackbar/show", {
          message: "Missing number in text field",
          type: "warning",
          timeout: 5,
        });
      }
    },
  },
  watch: {
    firstSix: function () {
      if (this.firstSix.length > 5) {
        this.$refs.nextInput.focus();
      }
    },
  },
};
</script>
