<template>
  <v-tab-item>
    <v-card flat class="sixth rounded-0  pt-4 ">
      <v-card-text>
        <v-row class="d-flex align-center sm-mx-auto ml-auto">
          <v-col cols="12" sm="9" md="9" lg="8" xl="6" class="mx-auto">
            <v-col cols="6" class="mx-auto ">
            <v-select
          :items="[10,20,30,50,75,100]"
          label="Baskets per page"
          v-model="per_page_items"
          class="md-mx-16"
        ></v-select>
            </v-col>
            <div
              class="d-flex justify-space-between flex-sm-row flex-column sixth mb-1"
            >
              <div v-for="(item, i) in state" :key="i" class="mx-auto">
                <v-switch v-model="state[i].bol" :color="item.color" class="mx-auto">
                  <template v-slot:label>
                    <p :class="item.icon + ' font-weight-medium my-auto'">
                      {{ item.keyword }}
                    </p>
                  </template>
                </v-switch>
              </div>
            </div>
            <v-divider></v-divider>
            <v-expansion-panels class="mt-6 d-block ">
              <transition-group>
                <v-expansion-panel
                  v-for="(item, key) in basketProductsArray"
                  :key="'basketProducts_' + key"
                >
                  <v-expansion-panel-header
                    elevation="0"
                    v-if="
                      (item.state === state[0].keyword &&
                        state[0].bol === true) ||
                      (item.state === state[1].keyword &&
                        state[1].bol === true) ||
                      (item.state === state[2].keyword && state[1].bol === true)
                    "
                  >
                    <v-icon
                      class="my-auto font-weight-bold mr-4"
                      :style="{ width: '20px' }"
                      :class="
                        item.state === state[0].keyword
                          ? state[0].icon
                          : item.state === state[1].keyword
                          ? state[1].icon
                          : item.state === state[2].keyword
                          ? state[2].icon
                          : ''
                      "
                      >mdi-brightness-1</v-icon
                    >
                    <p class="my-auto" :style="{ width: '80%' }">
                      Basket #{{ item.id }} of
                      <strong class="font-weight-medium primary--text"
                        >{{ item.totalAmount }}€</strong
                      > - {{ item.updateDate }}
                    </p>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    v-if="
                      (item.state === state[0].keyword &&
                        state[0].bol === true) ||
                      (item.state === state[1].keyword &&
                        state[1].bol === true) ||
                      (item.state === state[2].keyword && state[1].bol === true)
                    "
                  >
                    <v-card-text>
                      <p>State : {{ item.state }}</p>
                      <p>Total amount : {{ item.totalAmount }}€</p>
                      <p>Quantity : {{ item.basketProducts.qty }}</p>
                      <p>Creation date : {{ item.creationDate }}</p>
                      <p>Demo type : {{ item.demoType }}</p>
                      <p>Order number : {{ item.orderNumber }}</p>
                      <p>Shared : {{ item.shared }}</p>
                      <p>Basket content :</p>
                      <ul
                        v-for="(n, keyc) in item.basketProducts"
                        :key="'basketProducts_2' + keyc"
                      >
                        <li>
                          <a
                            class="primary--text font-weight-bold"
                            :href="
                              'https://weshop-insert-weshop.pubqlf.co.as8677.net/product/edit/' +
                              n.product.id
                            "
                            target="_blank"
                            >{{ n.product.name.fr }}</a
                          >
                          <ul>
                            <li>Marque : {{ n.product.marque }}</li>
                            <li>Id : {{ n.product.id }}</li>
                            <li>
                              Price : {{ n.product.price }}
                              €
                            </li>
                            <li>Quantity : {{ n.qty }}</li>
                          </ul>
                        </li>
                      </ul>
                    </v-card-text>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </transition-group>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="text-center">
      <v-container>
        <v-row justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="current_page"
                class=""
                :length="total_pages"
                :total-visible="9"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-tab-item>
</template>


<script>
import {paginate} from '@/assets/js/Methods';
export default {
  name: "Baskets",
  data() {
    return {
      current_page:1,
      per_page_items:20,
      state: [
        {
          keyword: "FINISH",
          color: "success",
          bol: true,
          icon: "success--text",
        },
        {
          keyword: "WAITING_PAYMENT",
          color: "warning",
          bol: true,
          icon: "warning--text",
        },
        {
          keyword: "IN_PROGRESS",
          color: "primary",
          bol: true,
          icon: "primary--text",
        },
      ],
      pattern: [
        {
          basketProducts: [
            {
              basketId: 0,
              id: 0,
              product: {
                category: {
                  id: 0,
                  image: "",
                  name: {},
                  parentCategory: 0,
                  sorting: 0,
                  visible: true,
                },
                description: {},
                ean: "",
                id: 0,
                image: "",
                keywords: {},
                marque: "",
                name: {},
                parentSpecificationId: "",
                price: 0,
              },
              qty: 0,
              rent: true,
            },
          ],
        },
      ],
    };
  },
  methods: {
    fomatArray(arr) {
      //get total qty of products for each basket.
      var cloneArr = [...arr];
      for (let i = 0; i < cloneArr.length; i++) {
        var qty = 0;
        for (let r = 0; r < cloneArr[i].basketProducts.length; r++) {
          qty += cloneArr[i].basketProducts[r].qty;
        }
        cloneArr[i].basketProducts.qty = qty;
      }
      return cloneArr.reverse();
    },
    paginate,
    selectState(arr,state){
      var cloneArr=[];
      for (let i = 0; i < arr.length; i++) {
        if((arr[i].state===state[0].keyword && state[0].bol==true) ||
         (arr[i].state===state[1].keyword && state[1].bol==true) || 
         (arr[i].state===state[2].keyword && state[2].bol==true)){
          cloneArr.push(arr[i])
          }
        }
        return cloneArr;
    }
  },
  computed: {
    basketProductsArray() {
      let pagination = this.paginate(this.selectState(this.fomatArray(this.$store.state.basketProducts), this.state),this.current_page,this.per_page_items);
      return pagination.data;
    },
    total_pages() {
      let pagination = this.paginate(this.selectState(this.fomatArray(this.$store.state.basketProducts), this.state),this.current_page,this.per_page_items);
      return pagination.total_pages;
    },
  },
};
</script>

